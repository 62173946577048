<template>
  <v-container
    id="timeline"
    fluid
    tag="section"
  >
    <administradores-component
      :role="Admin"
    />
  </v-container>
</template>

<script>
  export default {
    name: 'Administradores',

    components: {
      AdministradoresComponent: () => import('@/views/dashboard/component/Administradores'),
    },

    data: () => ({
      Admin: 'Administrador',
    }),
  }
</script>
